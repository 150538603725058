import type { LabeledValue } from 'antd/lib/select';
import type { Dayjs } from 'dayjs';

import type { LineChartData, SubjectTotal } from '../../../models/dashboard';
import { BarLineData, CategoryData } from '../../../models/dashboard';
import type { Region } from '../admin-community/adminCommunitySlice';
import { getRegionName } from '../admin-community/adminCommunitySlice';

export const datesRange: LabeledValue[] = [
  {
    label: '1 Week',
    value: '1 weeks',
  },
  {
    label: '1 Month',
    value: '1 months',
  },
  {
    label: '2 Months',
    value: '2 months',
  },
  {
    label: '3 Months',
    value: '3 months',
  },
  {
    label: '6 Months',
    value: '6 months',
  },
  {
    label: '12 Months',
    value: '12 months',
  },
  {
    label: 'All data',
    value: 'All data',
  },
];

export const getFiltersInfo = (startDate: Dayjs, endDate: Dayjs, region_number: number, regions: Region[]) => {
  return (
    startDate &&
    '(' +
      startDate.format('YYYY-MM-DD') +
      ' - ' +
      endDate.format('YYYY-MM-DD') +
      (region_number ? `, ${getRegionName(region_number, regions)}` : '') +
      ')'
  );
};

export const getPercetangeBarData = (data: SubjectTotal[] = []): [BarLineData, CategoryData] => {
  const totals: number[] = [];
  const labels: string[] = [];
  const total = data.reduce((acc, d) => {
    totals.push(d.total);
    labels.push(d.subject);
    return acc + d.total;
  }, 0);
  const percents = data.map((d) => Math.round((d.total / total) * 1000) / 10);
  return [new BarLineData(percents, totals), new CategoryData(labels)];
};

export const dateTimeFilterFormat = 'YYYY-MM-DD HH:mm:ss';

export const getChatDailyCSV = (data: LineChartData[]) => {
  let csv = 'Date,Total chats (per day),Total searches (per day),Total unique searchers (per day)\n';
  const [{ data: chats }, { data: searches }, { data: uniqueSearches }, { data: dates }] = data;

  for (let i = 0; i < dates.length; i++) {
    const line = `${(dates[i] as string).replace(',', '')},${chats[i]},${searches[i]},${uniqueSearches[i]}\n`;
    csv += line;
  }

  return csv;
};
