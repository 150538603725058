import type { AdvocateUpsert } from '../components/pages/advocate-upsert/advocateUpsertSlice';

export type Dow = [number, number, number]; // [day_of_week, hour_of_day, count]

export type ChatLengthBin = {
  min: number;
  max: number;
  label: string;
  total: number;
};

export type NameValue = {
  name: string;
  value: number;
};

export type SubjectTotal = {
  subject: string;
  total: number;
};

export type TreeMapData = {
  name: string;
  value: number;
  path: string;
  children: TreeMapData;
};

export class BarLineData {
  name = 'Percentage';
  type: 'bar' | 'line' = 'bar';
  label?: { show: boolean; position: string; fontSize: string; formatter: (b: { value: number }) => string };

  constructor(
    public data: number[],
    public totals: number[],
  ) {
    this.data = data;
    this.totals = totals;
  }
}

export class CategoryData {
  public type = 'category';

  constructor(public data: string[]) {
    this.data = data;
  }
}

export type OtherSubject = {
  subject: string;
  total: number;
};

export type IncompleteSurveys = {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  address: string;
  community: string;
  region: string;
  region_number: number;
  start_timestamp: string;
  end_timestamp: string;
  chat_duration: number;
};

export type MissedChat = {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  address: string;
  region: string;
  total: number;
};

export type InactiveAdvocate = Pick<
  AdvocateUpsert,
  'id' | 'first_name' | 'last_name' | 'email' | 'address' | 'community_id'
> & { full_name: string; community: string };

export type LineChartData = {
  data: string[] | number[];
  type: string;
  name: string | null;
};

export type GuestAlertsRegistered = {
  dates: string[];
  total_registered_users: number[];
  total_unregistered_users: number[];
};
