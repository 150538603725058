import { Button, DatePicker, Space } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';

import { SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

type RangeDatePickerFilterProps = Pick<
  FilterDropdownProps,
  'selectedKeys' | 'setSelectedKeys' | 'confirm' | 'clearFilters'
>;

export const RangeDatePickerFilter = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
}: RangeDatePickerFilterProps) => {
  return (
    <div style={{ padding: 8, width: 260 }}>
      <RangePicker
        picker="date"
        allowEmpty={[true, true]}
        allowClear={true}
        style={{ width: 'auto' }}
        format="MMM DD YYYY"
        disabledDate={(currentDate) => currentDate.isAfter(dayjs())}
        // defaultValue has issue https://github.com/ant-design/ant-design/issues/44905
        defaultPickerValue={[dayjs().subtract(1, 'M'), dayjs()]}
        value={[
          selectedKeys[0] ? dayjs(selectedKeys[0] as string) : null,
          selectedKeys[1] ? dayjs(selectedKeys[1] as string) : null,
        ]}
        onCalendarChange={(dates) => {
          if (dates) {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              setSelectedKeys([startDate.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD')]);
            } else if (startDate) {
              setSelectedKeys([startDate.format('YYYY-MM-DD'), '']);
            } else if (endDate) {
              setSelectedKeys(['', endDate?.format('YYYY-MM-DD')]);
            }
          }
        }}
      />
      <Space style={{ marginTop: 8 }}>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            setSelectedKeys(selectedKeys);
            confirm();
          }}
        >
          Search
        </Button>
        <Button
          size="small"
          onClick={(e) => {
            e.preventDefault();
            setSelectedKeys([]);
            clearFilters?.();
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};
