import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import type { StatusMatch } from '../abusive-guests/abusiveGuestsSlice';

const { logError } = RaygunErrorHandlerService();

export type ReportOfAbuse = {
  chat_id: string;
  start_timestamp: string;
  advocate_feedback: string | null;
  advocate_name: string;
  guest_feedback: string;
  guest_id: string;
  guest_alias: string;
  guest_blocked: StatusMatch;
  abuse_rating: number | null;
  abuse_reported_by: string;
  region: string;
  content: {
    messages: Message[];
  };
  total_count: number;
  org_name: string;
};

export type Message = {
  content: {
    message: string;
  };
  type: string | null;
  sequenceId: string | null;
  version: string;
  id: string | null;
  createdOn: string; // Comes from Azure Communications in UTC
  senderDisplayName: string | null;
  sender: string;
};

type ReportOfAbuseSliceType = {
  reportOfAbuse: ReportOfAbuse[];
  reportOfAbuseParam: RequestParams<ReportOfAbuse>;
  apiStatus: APIStatus;
  apiRatingStatus: APIStatus;
  showModal: boolean;
};

type ChangeRating = {
  chat_id: string;
  rating: number;
};

const initialState: ReportOfAbuseSliceType = {
  reportOfAbuse: [],
  reportOfAbuseParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<ReportOfAbuse>('start_timestamp', 'descend'),
    filteredInfo: null,
  },
  apiStatus: APIStatus.IDLE,
  apiRatingStatus: APIStatus.IDLE,
  showModal: false,
};

export const fetchReportOfAbuse = createAsyncThunk<ReportOfAbuse[], undefined, AsyncThunkConfig>(
  'reportOfAbuse/fetchReportOfAbuse',
  async (_, thunkAPI) => {
    try {
      const { reportOfAbuseSlice } = thunkAPI.getState();
      const response = await axios.post('v0_get_report_of_abuse', reportOfAbuseSlice.reportOfAbuseParam);
      return response as unknown as ReportOfAbuse[];
    } catch (e) {
      logError(e, ['reportOfAbuseSlice', 'fetchReportOfAbuse']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const changeReportOfAbuseRating = createAsyncThunk<string, ChangeRating, AsyncThunkConfig>(
  'reportOfAbuse/changeReportOfAbuseRating',
  async (rating: ChangeRating, thunkAPI) => {
    try {
      const response = await axios.post('v0_set_abuse_rating', rating);
      if (response) {
        thunkAPI.dispatch(showEditModal(false));
        thunkAPI.dispatch(fetchReportOfAbuse());
        notification.success({
          message: 'Success',
          description: 'Rating has been sucessfully changed.',
        });
        return response as unknown as string;
      }
      return '';
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
      logError(e, ['reportOfAbuseSlice', 'changeReportOfAbuseRating']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const reportOfAbuseSlice = createSlice({
  name: 'reportOfAbuse',
  initialState,
  reducers: {
    handleReportOfAbuseTableChange: (state, action: PayloadAction<TableChange<ReportOfAbuse>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<ReportOfAbuse>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.reportOfAbuseParam.pagination = action.payload.pagination;
      state.reportOfAbuseParam.sortedInfo = sorter;
      state.reportOfAbuseParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.reportOfAbuseParam.pagination = initialState.reportOfAbuseParam.pagination;
      state.reportOfAbuseParam.sortedInfo = initialState.reportOfAbuseParam.sortedInfo;
      state.reportOfAbuseParam.filteredInfo = initialState.reportOfAbuseParam.filteredInfo;
    },
    handleNavigationPageChange: (state, action: PayloadAction<FilteredInfo>) => {
      state.reportOfAbuseParam.filteredInfo = action.payload;
    },
    showEditModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportOfAbuse.pending, (state, _action) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchReportOfAbuse.fulfilled, (state, action) => {
        state.reportOfAbuse = action.payload;
        state.apiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchReportOfAbuse.rejected, (state, _action) => {
        state.apiStatus = APIStatus.ERROR;
      })
      .addCase(changeReportOfAbuseRating.pending, (state, _action) => {
        state.apiRatingStatus = APIStatus.PENDING;
      })
      .addCase(changeReportOfAbuseRating.fulfilled, (state, _action) => {
        state.apiRatingStatus = APIStatus.FULFILLED;
      })
      .addCase(changeReportOfAbuseRating.rejected, (state, _action) => {
        state.apiRatingStatus = APIStatus.ERROR;
      });
  },
});

export const { handleReportOfAbuseTableChange, clearFilter, handleNavigationPageChange, showEditModal } =
  reportOfAbuseSlice.actions;
