import type { SorterResult, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type AdminAdvocateHours = {
  id: string;
  advocate_name: string;
  first_name: string;
  last_name: string;
  region: string;
  months_online: string[];
  hours_online: number[];
  account_status: string;
  total_count: number;
  org_name: string;
};

type RequestAdminAdvocateHoursParam = {
  pagination: TablePaginationConfig;
  sortedInfo: AdminAdvocateHoursSortedInfo;
  filteredInfo: FilteredInfo;
};

export class AdminAdvocateHoursSortedInfo implements SorterResult<AdminAdvocateHours> {
  columnKey: keyof AdminAdvocateHours = 'advocate_name';
  field: keyof AdminAdvocateHours = 'advocate_name';
  order: SortOrder = 'ascend';
}

type AdminAdvocatesHoursSliceType = {
  adminAdvocateHoursData: AdminAdvocateHours[];
  requestAdminAdvocateHoursParam: RequestAdminAdvocateHoursParam;
  adminApiStatus: APIStatus;
  adminAdvocateHourMonthColumns: string[];
};

const initialState: AdminAdvocatesHoursSliceType = {
  adminAdvocateHoursData: [],
  requestAdminAdvocateHoursParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new AdminAdvocateHoursSortedInfo(),
    filteredInfo: null,
  },
  adminApiStatus: APIStatus.IDLE,
  adminAdvocateHourMonthColumns: [],
};

export const fetchadminAdvocateHours = createAsyncThunk<AdminAdvocateHours[], undefined, AsyncThunkConfig>(
  'adminAdvocateHours/fetchadminAdvocateHours',
  async (_, thunkAPI) => {
    try {
      const { adminAdvocateHoursSlice } = thunkAPI.getState();
      const response = await axios.post(
        'v0_get_advocate_hours',
        adminAdvocateHoursSlice.requestAdminAdvocateHoursParam,
      );
      return response as unknown as AdminAdvocateHours[];
    } catch (e) {
      logError(e, ['adminAdvocateHoursSlice', 'fetchadminAdvocateHours']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminAdvocateHoursSlice = createSlice({
  name: 'adminAdvocateHours',
  initialState,
  reducers: {
    handleAdvocateHoursTableChange: (state, action: PayloadAction<TableChange<AdminAdvocateHours>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as AdminAdvocateHoursSortedInfo;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminAdvocateHoursParam.pagination = action.payload.pagination;
      state.requestAdminAdvocateHoursParam.sortedInfo = sorter;
      state.requestAdminAdvocateHoursParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminAdvocateHoursParam.pagination = initialState.requestAdminAdvocateHoursParam.pagination;
      state.requestAdminAdvocateHoursParam.sortedInfo = initialState.requestAdminAdvocateHoursParam.sortedInfo;
      state.requestAdminAdvocateHoursParam.filteredInfo = initialState.requestAdminAdvocateHoursParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchadminAdvocateHours.pending, (state, _action) => {
        state.adminApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchadminAdvocateHours.fulfilled, (state, action) => {
        state.adminAdvocateHoursData = action.payload;
        if (action.payload.length > 0) {
          state.adminAdvocateHourMonthColumns = action.payload[0].months_online;
        }
        state.adminApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchadminAdvocateHours.rejected, (state, _action) => {
        state.adminApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleAdvocateHoursTableChange, clearFilter } = adminAdvocateHoursSlice.actions;
