import { notification } from 'antd';

import { Role } from '../../../enum';
import {
  notifyMissingFields,
  validateAsAdmin,
  validateAsFirstAlertsRole,
  validateAsServiceProviderManagerRole,
} from '../../../helpers/utils.helper';
import type { AdminManager } from './adminManagerSlice';

const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const validateAdminManager = (adminManager: AdminManager, currentUserRole: Role) => {
  const missingRequiredFields = [];
  if (!adminManager.first_name) missingRequiredFields.push('first name');
  if (!adminManager.last_name) missingRequiredFields.push('last name');
  if (!adminManager.email) missingRequiredFields.push('email');
  if (!adminManager.mobile_phone) missingRequiredFields.push('mobile_phone');
  if (!adminManager.role) missingRequiredFields.push('role');
  if (adminManager.role === Role.MANAGER && (!adminManager.region_numbers || adminManager.region_numbers.length < 1))
    missingRequiredFields.push('regions');
  if (
    validateAsAdmin(currentUserRole) &&
    validateAsFirstAlertsRole(adminManager.role) &&
    !adminManager.first_alerts_community_id
  )
    missingRequiredFields.push('FirstAlerts community');
  if (
    validateAsAdmin(currentUserRole) &&
    validateAsServiceProviderManagerRole(adminManager.role) &&
    !adminManager.service_provider_organization_id
  )
    missingRequiredFields.push('service provider organization');
  if (validateAsAdmin(currentUserRole) && !adminManager.org_code) missingRequiredFields.push('organization');

  if (missingRequiredFields.length > 0) {
    return notifyMissingFields(missingRequiredFields);
  } else if (!adminManager.email.match(/^[^@]+@[^@]+\.\w\w+$/g)) {
    notification.error({
      message: 'Invalid email',
      description: 'Please provide a valid email.',
    });
    return false;
  } else if (adminManager.mobile_phone && !adminManager.mobile_phone.match(phoneRegex)) {
    notification.error({
      message: 'Invalid mobile phone number',
      description: 'Please provide a valid mobile phone number.',
    });
    return false;
  } else if (!adminManager.id && adminManager.pass && adminManager.pass.length < 14) {
    notification.error({
      message: 'Initial password is too short',
      description: 'Please provide an initial password at least 14 characters long.',
    });
    return false;
  } else {
    return true;
  }
};
